<template>
  <div class="about">
   <inicio/>
  </div>
</template>
<script>
// @ is an alias to /src
import inicio from "@/components/inicio.vue";

export default {
  name: "about",
  components: {
    inicio
  }
};
</script>
