<template>
  <v-container fluid fill-height>
    <v-layout align-top justify-center>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            CATEGORIAS DE SERVICIOS
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            v-if="desserts.length > 0"
          >
            <template v-slot:[`item.estado`]="{ item }">
              <v-chip :color="estados[0].color" v-if="item.estado == 1">{{ estados[0].estado }}</v-chip>
              <v-chip :color="estados[1].color" v-if="item.estado == 0" dark>{{ estados[1].estado }}</v-chip>
            </template>

            <template v-slot:[`item.descripcion`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.descripcion"
                large
                persistent
                @save="save(props.item)"
                @cancel="cancel"
                @open="open"
                @close="close"
              >
                {{ props.item.descripcion }}
                <template v-slot:input>
                  <v-text-field v-model="props.item.descripcion" label="NOMBRE" single-line counter></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            item-key="name"
            loading
            loading-text="Cargando...   Por favor espere"
            v-if="desserts.length === 0"
          ></v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
    <v-btn bottom color="secondary" dark fab fixed right @click="dialog = !dialog">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Nueva Categoria</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre*"
                  hint
                  v-model="nombrecat"
                  required
                  :rules="valnombrecat"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="guardardata">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: "categoriasservicio",
  data() {
    return {
      search: "",
      dialog: false,
      headers: [
        { text: "NOMBRE", value: "descripcion" },
        { text: "ESTADO", value: "estado" }
      ],
      desserts: [],
      estados: [
        { estado: "Activo", color: "success" },
        { estado: "Desabilitado", color: "error" }
      ],
      snack: false,
      snackColor: "",
      snackText: "",
      categorias: [],
      mensaje: "",
      size: 0,
      nombrecat: "",
      valnombrecat: [value => !!value || "Ingrese el nombre de la Categoria"]
    };
  },
  created() {
    this.cargardatacategoria()
  },
  watch: {
    dialog: function (val) {
      this.cargardatacategoria();
    }
  },
  methods: {
    save(item) {
      var url = "ser/cat/update/";
      var result = null;
      const postData = {
        idcategoriaservicio: item.idcategoriaservicio.trim(),
        descripcion: item.descripcion.trim(),
        estado: item.estado.trim()
      };
      console.log(postData);
      this.$http.post(url, postData).then(res => {
        if (res.status === 200) {
          result = res.body;
          this.size = result.size;
          this.mensaje = result.objeto[0].mensaje;
          this.snack = true;
          this.snackColor = "success";
          this.snackText = this.mensaje;
        }
      });
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Cancelado";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Abriendo edición";
    },
    close() {
      console.log("Dialogo cerrado");
    },
    dialogedit(value, data) {
      this.dialog = value;
      if (value == true) {
        this.data = data;
      }
    },
    guardardata() {
      if (this.nombrecat.trim() == "") {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Complete todos los campos";
      } else {
        var url = "ser/cat/";
        var result = null;
        const postData = {
          descripcion: this.nombrecat.trim()
        };
        console.log(postData);
        this.$http.post(url, postData).then(res => {
          if (res.status === 200) {
            result = res.body;
            this.size = result.size;
            this.mensaje = result.objeto[0].mensaje;
            this.dialog = false;
            this.snack = true;
            this.snackColor = "success";
            this.snackText = this.mensaje;
          }
        });
      }
    },
    cargardatacategoria() {
      var url2 = "ser/";
      var result2 = null;
      this.$http.get(url2).then(res => {
        if (res.status === 200 || res.status === 304) {
          result2 = res.body;
          if (result2.size > 0) {
            this.size = result2.size;
            this.desserts = result2.objeto;
            console.log(this.categorias);
          } else {
            this.size = result2.size;
            this.mensaje = result2.objeto[0].mensaje;
            console.log(this.mensaje);
          }
        }
      });
    }
  }
};
</script>