<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template v-for="item in items">
          <v-layout v-if="item.heading" :key="item.heading" row align-center>
            <v-flex xs6>
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-flex>
            <v-flex xs6 class="text-xs-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-flex>
          </v-layout>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" v-on:click="cambiovista(item.children[i].vista)">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" v-on:click="cambiovista(item.vista)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <div class="text-center" align-bottom>
            <v-btn rounded color="primary" dark>
              <v-icon left>close</v-icon>Cerrar Sesión
            </v-btn>
          </div>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="secondary" dark>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down">CONTROL NISIRA</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>apps</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>notifications</v-icon>
      </v-btn>
      <v-btn icon large>
        <v-avatar size="32px" item>
          <img srcset="../assets/nisiraicon.png" alt="" width="70%"> 
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-content>
      <empresas v-if="vista === 9"/>
      <servicio v-if="vista === 10"/>
      <categoriasservicio v-if="vista === 11"/>
      <controlservicio v-if="vista === 1"/>
      <usuario v-if="vista === 7"/>
      <valoracion v-if="vista === 5"/>
      <ranking v-if="vista === 4"/>
    </v-content>
  </v-app>
</template>
<style>
</style>
<script>
import empresas from "@/components/empresas.vue";
import servicio from "@/components/servicio.vue";
import categoriasservicio from "@/components/categoriaservicio.vue";
import controlservicio from "@/components/controlservicio.vue";


export default {
  name: "inicio",
  components: {
    empresas,
    servicio,
    categoriasservicio,
    controlservicio
  },
  data: () => ({
    drawer: null,
    items: [
      { icon: 'keyboard_arrow_up',
        'icon-alt':'apartment',
      text: "servicios",
      children: [
          { text: 'Control', vista: 1 },
          { text: 'Empresas' , vista: 9},
          { text: 'Servicio' , vista: 10 },
          { text: 'Categoria de Servicios' , vista: 11},          
        ],
      },
      { icon: "timeline", text: "Ranking", vista: 4},
      { icon: "star_border", text: "Valoracion" , vista: 5},
      { icon: "settings", text: "Ajustes" , vista: 6},
      { icon: "people", text: "Usuarios" , vista: 7},
      { icon: "help", text: "Ayuda" , vista: 8}
    ],
    vista: 1
  }),
  methods: {
    cambiovista(dato){
      this.vista = dato;
    }
  }
};
</script>