<template>
  <v-container fluid fill-height>
    <v-layout align-top justify-center>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            EMPRESAS REGISTRADAS
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            v-if="desserts.length > 0"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-plus-circle-outline
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(est, i) in estados"
                    :key="i"
                    @click="changeestado(item, i)"
                  >
                    <v-alert dense outlined :type="est.color">{{
                      est.estado
                    }}</v-alert>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.idempresa`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.idempresa"
                large
                persistent
                @save="save(props.item)"
                @cancel="cancel"
                @open="open"
                @close="close"
              >
                {{ props.item.idempresa }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.idempresa"
                    :rules="[max11chars]"
                    label="IDEMPRESA"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:[`item.ruc`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.ruc"
                large
                persistent
                @save="save(props.item)"
                @cancel="cancel"
                @open="open"
                @close="close"
              >
                <div>
                  <v-alert
                    dense
                    outlined
                    text
                    class="body-2"
                    :type="estados[props.item.estado].color"
                    >{{ props.item.ruc }}</v-alert
                  >
                </div>
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.ruc"
                    :rules="[max11chars]"
                    label="RUC"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:[`item.razonsocial`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.razonsocial"
                large
                persistent
                @save="save(props.item)"
                @cancel="cancel"
                @open="open"
                @close="close"
              >
                <div>{{ props.item.razonsocial }}</div>
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.razonsocial"
                    label="RAZON SOCIAL"
                    single-line
                    counter
                    autofocus
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.dominio`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.dominio"
                large
                persistent
                @save="save(props.item)"
                @cancel="cancel"
                @open="open"
                @close="close"
              >
                <div>{{ props.item.dominio }}</div>
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.dominio"
                    label="DOMINIO"
                    single-line
                    counter
                    autofocus
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            item-key="name"
            loading
            loading-text="Cargando...   Por favor espere"
            v-if="desserts.length === 0"
          ></v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
    <v-btn
      bottom
      color="secondary"
      dark
      fab
      fixed
      right
      @click="dialog = !dialog"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">NUEVA EMPRESA</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="RUC*"
                  v-model="ruc"
                  required
                  :rules="valruc"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Id Empresa*"
                  hint="ejmp: '001', etc"
                  v-model="idempresa"
                  required
                  :rules="validempresa"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Razón Social*"
                  v-model="razonsocial"
                  hint="Ejemplo: NOMBREEMPRESA.SAC"
                  :rules="valrazonsocial"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Dominio*"
                  v-model="dominio"
                  hint="El dominio termina en (.nisira.org)"
                  :rules="valdominio"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="guardardata">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogsub" width="800px">
      <v-card>
        <v-card-title>
          {{ empresa.idempresa }}
          <span> - </span>
          {{ empresa.razonsocial }}
          <v-spacer></v-spacer>
          <v-icon large @click="dialogsub = !dialogsub">
            mdi-close-circle
          </v-icon>
        </v-card-title>
        <v-card-subtitle>
          <span>{{ empresa.ruc }}</span>
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-spacer></v-spacer>
              <v-col sm="6" md="6" xs="12">
                <v-text-field
                  label="SUB-EMPRESA*"
                  v-model="subempresades"
                  required
                  :rules="valdessubempresa"
                ></v-text-field>
              </v-col>
              <v-btn rounded color="primary" dark @click="cargariddoc(empresa)">
                Añadir
              </v-btn>
            </v-row>
            <v-row align="center">
              <v-col sm="12" md="12" xs="12">
                <template>
                  <v-data-table
                    :headers="subemheadres"
                    :items="subempresas"
                    loading
                    loading-text="SIN INFORMACIÓN"
                    v-if="subempresas.length === 0"
                  ></v-data-table>
                  <v-data-table
                    :headers="subemheadres"
                    :items="subempresas"
                    sort-by="descripcion"
                    class="elevation-1"
                    :items-per-page="5"
                    v-if="subempresas.length > 0"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small @click="deleteItemsub(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:[`item.descripcion`]="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.descripcion"
                        large
                        persistent
                        @save="savesub(props.item)"
                        @cancel="cancel"
                        @open="open"
                        @close="close"
                      >
                        <div>{{ props.item.descripcion }}</div>
                        <template v-slot:input>
                          <v-text-field
                            v-model="props.item.descripcion"
                            label="SUB EMPRESA"
                            single-line
                            autofocus
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                  </v-data-table>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "empresas",
  data() {
    return {
      search: "",
      dialog: false,
      dialogsub: false,
      empresa: {},
      codigodoc: "",
      subempresades: "",
      subemheadres: [
        {
          text: "SUBEMPRESA",
          value: "descripcion",
        },
        {
          text: "ACCIONES",
          value: "actions",
          sortable: false,
        },
      ],
      subempresas: [],
      headers: [
        {
          text: "RUC",
          value: "ruc",
        },
        {
          text: "ID EMPRESA",
          value: "idempresa",
        },
        {
          text: "RAZON SOCIAL",
          value: "razonsocial",
        },
        {
          text: "DOMINIO",
          value: "dominio",
        },
        {
          text: "ACCIONES",
          value: "actions",
          sortable: false,
        },
      ],
      desserts: [],
      estados: [
        {
          estado: "Desactivar",
          color: "error",
        },
        {
          estado: "Activar",
          color: "success",
        },
      ],
      snack: false,
      snackColor: "",
      snackText: "",
      ruc: "",
      idempresa: "",
      razonsocial: "",
      dominio: "",
      valruc: [
        (value) => !!value || "Ingrese su RUC",
        (value) => (value || "").length <= 11 || "Ingrese un Ruc Valido",
      ],
      validempresa: [(value) => !!value || "Ingrese el ID empresa asignado"],
      valrazonsocial: [(value) => !!value || "Ingrese el nombre de la empresa"],
      valdominio: [
        (value) => !!value || "El dominio deve terminar en (.nisira.org)",
        (value) => value.indexOf('_') == -1 || "No se admite simboles (_@-.: etc)",
        (value) => value.indexOf('@') == -1 || "No se admite simboles (_@-.: etc)",
        (value) => value.indexOf('-') == -1 || "No se admite simboles (_@-.: etc)",
        (value) => value.indexOf('$') == -1 || "No se admite simboles (_@-.: etc)",
        (value) => value.indexOf('/') == -1 || "No se admite simboles (_@-.: etc)",
        (value) => value.indexOf('%') == -1 || "No se admite simboles (_@-.: etc)",
        (value) => value.indexOf('(') == -1 || "No se admite simboles (_@-.: etc)",
        (value) => value.indexOf(')') == -1 || "No se admite simboles (_@-.: etc)",
        (value) => value.indexOf('#') == -1 || "No se admite simboles (_@-.: etc)",
      ],
      valdessubempresa: [
        (value) => !!value || "Ingrese sub Empresa",
        (value) => (value || "").length > 0 || "Ingrese una sub empresa validad",
      ],
      max11chars: (v) => v.length <= 11 || "Ingrese un Ruc Valido",
    };
  },
  created() {
    this.cargardataempresa();
  },
  watch: {
    dialog: function (val) {
      this.cargardataempresa();
    },
  },
  methods: {
    changeestado(item, estado) {
      var url = "ver/update/";
      var result = null;
      const postData = {
        ruc: item.ruc,
        idempresa: item.idempresa,
        razonsocial: item.razonsocial,
        estado: estado,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200) {
          result = res.body;
          this.snack = true;
          this.snackColor =
            result.confirmacion != ""
              ? "success"
              : result.error != ""
              ? "error"
              : "";
          this.snackText =
            result.confirmacion != ""
              ? result.confirmacion
              : result.error != ""
              ? result.error
              : "Sin respuesta";
          this.cargardataempresa();
        }
      });
    },
    save(item) {
      var url = "ver/update/";
      var result = null;
      const postData = {
        ruc: item.ruc,
        idempresa: item.idempresa,
        razonsocial: item.razonsocial,
        estado: item.estado,
        domain: item.dominio,
      };
      console.info('info', postData);
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200) {
          result = res.body;
          this.snack = true;
          this.snackColor =
            result.confirmacion != ""
              ? "success"
              : result.error != ""
              ? "error"
              : "info";
          this.snackText =
            result.confirmacion != ""
              ? result.confirmacion
              : result.error != ""
              ? result.error
              : "Sin respuesta";
          this.cargardataempresa();
        }
      });
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Cancelado";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Abriendo edición";
    },
    close() {
      console.log("Dialogo cerrado");
    },
    dialogedit(value, data) {
      this.dialog = value;
      if (value == true) {
        this.data = data;
      }
    },
    guardardata() {
      if (
        this.idempresa.trim() == "" ||
        this.ruc.trim() == "" ||
        this.razonsocial.trim() == ""
      ) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Complete todos los campos";
      } else if (this.ruc.trim().length > 11 || this.ruc.trim().length < 10) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "El Ruc no es admitido";
      } else if (
        this.idempresa.trim().length > 3 ||
        this.idempresa.trim().length < 3
      ) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "El ID empresa no es admitido";
      } else {
        var url = "ver/";
        var result = null;
        const postData = {
          ruc: this.ruc.trim(),
          idempresa: this.idempresa.trim(),
          razonsocial: this.razonsocial.trim(),
          domain: this.dominio.trim(),
        };
        this.$http.post(url, postData).then((res) => {
          if (res.status === 200) {
            result = res.body;
            this.size = result.size;
            this.dialog = false;
            this.snack = true;
            this.snackColor =
              result.confirmacion != ""
                ? "success"
                : result.error != ""
                ? "error"
                : "info";
            this.snackText =
              result.confirmacion != ""
                ? result.confirmacion
                : result.error != ""
                ? result.error
                : "Sin respuesta";
          }
        });
      }
    },
    cargardataempresa() {
      var url = "ver/lista/";
      var result = null;
      this.$http.get(url).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          if (result.size > 0) {
            this.size = result.size;
            this.desserts = result.objeto;
          } else {
            this.size = result.size;
            this.mensaje = result.objeto[0].mensaje;
          }
        }
      });
    },
    cargardatasubempresa(item) {
      var url = "ver/sub/list/";
      var result = null;
      const postData = {
        ruc: item.ruc,
        idempresa: item.idempresa,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200) {
          result = res.body;
          this.subempresas = result.objeto;
        }
      });
    },
    editItem(item) {
      this.dialogsub = !this.dialogsub;
      this.subempresas = [];
      this.empresa = item;
      this.cargardatasubempresa(item);
    },
    cargariddoc(empresa) {
      var url = "doc/";
      var result = null;
      this.$http.get(url).then((res) => {
        if (res.status === 200 || res.status === 304) {
          result = res.body;
          this.codigodoc = result.objeto[0]["codigo"];
          this.addsubempresa(empresa);
        }
      });
    },
    savesub(itemsub) {
      var url = "ver/sub/";
      var result = null;
      const postData = {
        idsubempresa: itemsub.idsubempresa,
        ruc: itemsub.ruc,
        idempresa: itemsub.idempresa,
        descripcion: itemsub.descripcion,
        estado: itemsub.estado,
        tipo: 1,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200) {
          result = res.body;
          this.snack = true;
          this.snackColor =
            result.confirmacion != ""
              ? "success"
              : result.error != ""
              ? "error"
              : "info";
          this.snackText =
            result.confirmacion != ""
              ? result.confirmacion
              : result.error != ""
              ? result.error
              : "Sin respuesta";
          this.cargardatasubempresa(itemsub);
        }
      });
    },
    addsubempresa(empresa) {
      if (this.codigodoc.trim() != "") {
        var url = "ver/sub/";
        var result = null;
        const postData = {
          idsubempresa: this.codigodoc,
          ruc: empresa.ruc,
          idempresa: empresa.idempresa,
          descripcion: this.subempresades,
          estado: 1,
          tipo: 1,
        };
        this.$http.post(url, postData).then((res) => {
          if (res.status === 200) {
            result = res.body;
            this.snack = true;
            this.snackColor =
              result.confirmacion != ""
                ? "success"
                : result.error != ""
                ? "error"
                : "info";
            this.snackText =
              result.confirmacion != ""
                ? result.confirmacion
                : result.error != ""
                ? result.error
                : "Sin respuesta";
            this.cargardatasubempresa(empresa);
          }
        });
      } else {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "iddocumento vacio: ";
      }
    },
    deleteItemsub(item) {
      var url = "ver/sub/";
      var result = null;
      const postData = {
        idsubempresa: item.idsubempresa,
        ruc: item.ruc,
        idempresa: item.idempresa,
        descripcion: item.descripcion,
        estado: item.estado,
        tipo: 0,
      };
      this.$http.post(url, postData).then((res) => {
        if (res.status === 200) {
          result = res.body;
          this.snack = true;
          this.snackColor =
            result.confirmacion != ""
              ? "success"
              : result.error != ""
              ? "error"
              : "info";
          this.snackText =
            result.confirmacion != ""
              ? result.confirmacion
              : result.error != ""
              ? result.error
              : "Sin respuesta";
          this.cargardatasubempresa(item);
        }
      });
    },
    deleteItem(item) {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "funcion no activada";
    },
  },
};
</script>
